import {MDCRipple, MDCRippleFoundation, util} from '@material/ripple';

(function ($) {
	$(document).ready(function () {


		if ($('.button').length > 0) {
			const ssurface_blue = document.querySelectorAll('.button');

			for (let i = 0; i < ssurface_blue.length; ++i) {
				MDCRipple.attachTo(ssurface_blue[i]);
			}
		}

		if ($('.submenu-item a').length > 0) {
			const surface = document.querySelectorAll('.submenu-item a');

			for (let i = 0; i < surface.length; ++i) {
				MDCRipple.attachTo(surface[i]);
			}
		}


		if ($('.form-button').length > 0) {
			const form_button = document.querySelectorAll('.form-button');

			for (let i = 0; i < form_button.length; ++i) {
				MDCRipple.attachTo(form_button[i]);
			}
		}

	});
})(jQuery);